<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">文章标签</h1>
    </div>
    <div slot="extra">
      <div class="more-info">
        <a-button style="margin-left: 16px" type="primary" @click="jumpAdd">
          <a-icon type="plus-circle"/>
          增加
        </a-button>
        <a-button style="margin-left: 16px" type="danger" @click="MultipleDel">
          <a-icon type="delete"/>
          删除
        </a-button>
      </div>
    </div>
    <a-card>
      <a-input style="width: 200px" slot="extra" v-model="queryParam.q" @change="handleTagChange">
        <a-icon slot="suffix" type="search" @click="tagSearch"/>
      </a-input>
      <a-table
        :rowKey="record => record.id"
        @change="tableChange"
        ref="table"
        size="default"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
        <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
        <span slot="status" slot-scope="status">
        <template>
           <a-badge :status="status| stepsFilter" :text="status | statusFilter"/>
        </template>
      </span>
        <span slot="operation" slot-scope="text, record">
        <template>
          <a type="edit" @click="tagEdit(record)">编辑</a>
          <a style="margin-left:8px" @click="tagDel(record)">删除</a>
        </template>
      </span>
      </a-table>
      <a-modal
        title="文章标签添加"
        v-model="addVisible"
        okText="添加"
        cancelText="取消"
        width="960px"
        @ok="handleOk">
        <article-tag-form ref="tagAddRef" @Success="handleAddSuccess" @Error="handleAddError"></article-tag-form>
      </a-modal>
      <a-modal
        title="文章标签修改"
        v-model="editVisible"
        okText="修改"
        cancelText="取消"
        width="960px"
        @ok="handleEditOk">
        <article-tag-form ref="tagEditRef" @Success="handleAddSuccess" @Error="handleAddError"></article-tag-form>
      </a-modal>
    </a-card>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '../../../api/service.url'
  import ArticleTagForm from './ArticleTagForm'

  export default {
    name: 'tag',
    components: { ArticleTagForm, PageLayout },
    data() {
      return {
        pagination: {
          total: 0,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        queryParam: {
          steps: '',
          number: '0',
          size: '10',
          direction: 'DESC',
          order: 'dateCreated',
          q: ''
        },
        tempIdList: [],
        editVisible: false,
        addVisible: false,
        selectedRowKeys: [],
        columns: [{
          title: '序号',
          dataIndex: 'serial',
          key: 'serial',
          scopedSlots: { customRender: 'serial' }
        },
          {
            title: '标签名称',
            dataIndex: 'name',
            key: 'name',
            width: 400
          },
          {
            title: '文章数量',
            dataIndex: 'sumArticle',
            key: 'sumArticle',
            width: 100
          },
          {
            title: '排序',
            dataIndex: 'sorter',
            key: 'sorter'
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 80,
            scopedSlots: { customRender: 'status' }
          },
          {
            title: '最近更新',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated'
          },
          {
            title: '创建时间',
            dataIndex: 'dateCreated',
            key: 'dateCreated'
          },
          {
            title: '操作',
            align: 'center',
            dataIndex: 'operation',
            key: 'operation',
            width: 100,
            scopedSlots: { customRender: 'operation' }
          }
        ],
        tableData: []

      }
    },
    filters: {
      stepsFilter(status) {
        const statusMap = {
          true: 'success',
          false: 'error'
        }
        return statusMap[status]
      },
      statusFilter(status) {
        const statusMap = {
          true: '正常',
          false: '禁用'
        }
        return statusMap[status]
      }
    },
    created() {
      this.tagSearch()
    },
    mounted() {
    },
    methods: {
      onSelectChange(selectedRowKeys, selectedRows) {
        this.tempIdList = []
        this.selectedRowKeys = selectedRowKeys
        for (let item of selectedRows) {
          this.tempIdList.push(item.id)
        }
      },
      jumpAdd() {
        this.addVisible = true
      },
      handleOk() {
        this.$refs['tagAddRef'].addTagSubmit()
      },
      handleEditOk() {
        this.$refs['tagEditRef'].addTagSubmit()
      },
      handleAddSuccess(value) {
        this.addVisible = false
        this.editVisible = false
        this.tagSearch()
      },
      handleAddError() {
        this.addVisible = false
      },
      MultipleDel() {
        this.$http(this, {
          url: SERVICE_URLS.article.tag.delMore,
          data: this.tempIdList,
          success: () => {
            this.tagSearch()
          }
        })
      },
      handleTagChange() {
        this.tagSearch()
      },
      tagSearch() {
        this.$http(this, {
          url: SERVICE_URLS.article.tag.search,
          noTips: true,
          data: this.queryParam,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
          }
        })
      },
      tagEdit(record) {
        this.editVisible = true
        this.$nextTick(() => {
          this.$refs['tagEditRef'].loadData(record.id)
        })
      },
      tableChange(pagination, filters, sorter) {
        this.queryParam.size = pagination.pageSize
        this.queryParam.number = pagination.current - 1
        if (sorter.order === 'ascend') {
          this.queryParam.direction = 'ASC'
        } else {
          this.queryParam.direction = 'DESC'
        }
        this.queryParam.order = sorter.field
        this.tagSearch()
      },
      tagDel(record) {
        this.$refs['tagEditRef'].handleTagDel(record.id)
      }
    }
  }
</script>

<style scoped>

</style>